import { useEffect, useState } from 'react';
import Script from 'next/script';
import { auth } from "../../utils/Authentication";

export default function Ads() {
  const [isUnlimited, setIsUnlimited] = useState(false);

  useEffect(() => {
    const checkUnlimitedUser = async () => {
      if (auth.currentUser?.email) {
        const response = await fetch("/api/user/type", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user: auth.currentUser,
          }),
        });

        const { isUnlimitedUser } = await response.json();
        setIsUnlimited(isUnlimitedUser);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        checkUnlimitedUser();
      } else {
        setIsUnlimited(false);
      }
    });

    return () => unsubscribe();
  }, []);

  if (isUnlimited) {
    return null;
  }

  return (
    <>
      <Script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8378558199375807"
        crossOrigin="anonymous"
        strategy="lazyOnload"
      />
      <div className="ad-container">
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-8378558199375807"
          data-ad-slot="auto"
          data-ad-format="auto"
          data-full-width-responsive="true"
        />
      </div>
    </>
  );
} 