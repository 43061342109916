import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { t } from "i18next";
import { auth } from "../../utils/Authentication";
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";
import { Sparkles, Menu } from "lucide-react";
import LanguageSwitcher from "./LanguageSwitcher";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "../ui/sheet";

const Header: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  const navigationLinks: any[] = [
    //{ href: "/examples", label: t("header.examples") }
  ];

  return (
    <header className="sticky top-0 z-50 w-full border-b border-[#2D6250]/20 bg-white/75 backdrop-blur-lg">
      <div className="mx-4 flex h-14 items-center justify-between">
        <Link href="/" className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="h-8 w-8 text-[#2D6250]"
          >
            <path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3" />
          </svg>
          <span className="hidden md:inline-block ml-2 font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-[#2D6250] to-[#2D6250]/80">
            Professionalize It To Me
          </span>
        </Link>

        <div className="flex items-center gap-4">
          <nav className="hidden md:flex items-center gap-6">
            {navigationLinks.map((link) => (
              <Link 
                key={link.href}
                href={link.href} 
                className="text-sm font-medium text-muted-foreground transition-colors hover:text-[#2D6250]"
              >
                {link.label}
              </Link>
            ))}
          </nav>

          <div className="hidden md:flex items-center gap-4">
            <LanguageSwitcher />

            {isLoggedIn ? (
              <>
                <Link href="/dashboard">
                  <Button variant="ghost" size="sm">
                    {t("header.dashboard")}
                  </Button>
                </Link>
                <Button 
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    auth.signOut();
                    router.push("/");
                  }}
                >
                  {t("header.logout")}
                </Button>
              </>
            ) : (
              <>
                <Link href="/sign-in">
                  <Button variant="ghost" size="sm">
                    {t("header.sign-in")}
                  </Button>
                </Link>
                <Link href="/sign-up">
                  <Button size="sm" className="bg-[#2D6250] hover:bg-[#2D6250]/90">
                    <Sparkles className="mr-2 h-4 w-4" />
                    {t("header.try-for-free")}
                  </Button>
                </Link>
              </>
            )}
          </div>

          <Sheet>
            <SheetTrigger asChild className="md:hidden">
              <Button variant="ghost" size="icon" className="h-8 w-8">
                <Menu className="h-5 w-5" />
              </Button>
            </SheetTrigger>
            <SheetContent side="right" className="w-[85vw] sm:w-[350px]">
              <div className="flex flex-col gap-4">
                <nav className="flex flex-col gap-4">
                  {navigationLinks.map((link) => (
                    <Link 
                      key={link.href}
                      href={link.href} 
                      className="text-sm font-medium text-muted-foreground transition-colors hover:text-[#2D6250]"
                    >
                      {link.label}
                    </Link>
                  ))}
                </nav>

                <Separator className="my-2" />
                <LanguageSwitcher />

                {isLoggedIn ? (
                  <div className="flex flex-col gap-2">
                    <Link href="/dashboard" className="w-full">
                      <Button variant="ghost" className="w-full justify-start" size="sm">
                        {t("header.dashboard")}
                      </Button>
                    </Link>
                    <Button 
                      variant="ghost" 
                      className="w-full justify-start"
                      size="sm"
                      onClick={() => {
                        auth.signOut();
                        router.push("/");
                      }}
                    >
                      {t("header.logout")}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col gap-2">
                    <Link href="/sign-in" className="w-full">
                      <Button variant="ghost" className="w-full justify-start" size="sm">
                        {t("header.sign-in")}
                      </Button>
                    </Link>
                    <Link href="/sign-up" className="w-full">
                      <Button className="w-full bg-[#2D6250] hover:bg-[#2D6250]/90" size="sm">
                        <Sparkles className="mr-2 h-4 w-4" />
                        {t("header.try-for-free")}
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </header>
  );
};

export default Header;
